var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "team-member-select",
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.leftLoading,
              expression: "leftLoading",
            },
          ],
          staticClass: "left",
        },
        [
          _c(
            "div",
            { staticClass: "left-top" },
            [
              _c(
                "el-popover",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.teamTreeLoading,
                      expression: "teamTreeLoading",
                    },
                  ],
                  attrs: {
                    "popper-class": "team-tree-popover",
                    placement: "bottom-start",
                    width: "242",
                    trigger: "click",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return (() => {}).apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.teamTreePopoverVisible,
                    callback: function ($$v) {
                      _vm.teamTreePopoverVisible = $$v
                    },
                    expression: "teamTreePopoverVisible",
                  },
                },
                [
                  _c("el-tree", {
                    attrs: {
                      data: _vm.teamData,
                      props: _vm.defaultProps,
                      "highlight-current": false,
                      "expand-on-click-node": false,
                      "node-key": "teamNodeKey",
                      "default-expanded-keys": ["0_0_0"],
                    },
                    on: { "node-click": _vm.handleNodeClick },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ node, data }) {
                          return _c(
                            "span",
                            {
                              staticClass: "custom-tree-node",
                              class: data.teamId == _vm.teamId ? "active" : "",
                            },
                            [
                              _c("span", { attrs: { title: node.label } }, [
                                _vm._v(_vm._s(node.label)),
                              ]),
                              _c("i", [
                                _vm._v("(" + _vm._s(data.membersCount) + ")"),
                              ]),
                            ]
                          )
                        },
                      },
                    ]),
                  }),
                  _c("el-input", {
                    staticClass: "left-top-input team-input",
                    attrs: {
                      slot: "reference",
                      readonly: true,
                      placeholder: "选择团队",
                      "suffix-icon": "el-icon-arrow-down",
                    },
                    slot: "reference",
                    model: {
                      value: _vm.teamName,
                      callback: function ($$v) {
                        _vm.teamName = $$v
                      },
                      expression: "teamName",
                    },
                  }),
                ],
                1
              ),
              _c("el-input", {
                staticClass: "left-top-input",
                attrs: { placeholder: "搜索成员名称" },
                on: {
                  input: _vm.handleFilterByKeyword,
                  focus: _vm.handleHideTeamPopover,
                },
                model: {
                  value: _vm.filterKeyword,
                  callback: function ($$v) {
                    _vm.filterKeyword = $$v
                  },
                  expression: "filterKeyword",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "left-list",
              on: { click: _vm.handleHideTeamPopover },
            },
            [
              _c(
                "div",
                { staticClass: "left-list-title" },
                [
                  !_vm.isSingleSelect
                    ? _c(
                        "div",
                        {
                          staticClass: "name-box",
                          on: { click: _vm.handleMemberSelectAll },
                        },
                        [
                          _c("span", {
                            staticClass: "check-box",
                            class: _vm.isSelectAll ? "active" : "",
                          }),
                          _c("span", { staticClass: "name" }, [_vm._v("全选")]),
                        ]
                      )
                    : _c("div", { staticClass: "name-box" }, [_vm._v("人选")]),
                  _vm.isHistoryData
                    ? _c(
                        "el-dropdown",
                        {
                          attrs: { placement: "bottom" },
                          on: { command: _vm.handleFilterList },
                        },
                        [
                          _c("span", { staticClass: "el-dropdown-link" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.statusFilterText || "状态")
                            ),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right",
                            }),
                          ]),
                          _c(
                            "el-dropdown-menu",
                            {
                              staticClass: "status-dropdown-menu",
                              attrs: { slot: "dropdown" },
                              slot: "dropdown",
                            },
                            [
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "0" } },
                                [_vm._v("全部")]
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "1" } },
                                [_vm._v("正常")]
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "2" } },
                                [_vm._v("已离职")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("span", { staticClass: "left-list-title-status" }, [
                        _vm._v("状态"),
                      ]),
                ],
                1
              ),
              !_vm.filterLoginUser
                ? [
                    _vm.computedMemberFilterList.length > 0
                      ? _c(
                          "ul",
                          _vm._l(
                            _vm.computedMemberFilterList,
                            function (member) {
                              return _c(
                                "li",
                                {
                                  key: "member_" + member.userId,
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleMemberSelect(member)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "name-box" }, [
                                    _c("span", {
                                      staticClass: "check-box",
                                      class:
                                        _vm.selectedMemberIds.indexOf(
                                          member.userId
                                        ) > -1
                                          ? "active"
                                          : "",
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "name",
                                        attrs: {
                                          title: `${member.realName}@${member.nickname}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(member.realName) +
                                            "@" +
                                            _vm._s(member.nickname)
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "status",
                                      class: member.isDimission
                                        ? "is-dimission"
                                        : "",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          member.isDimission ? "已离职" : "正常"
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _c("p", { staticClass: "empty" }, [_vm._v("暂无数据")]),
                  ]
                : [
                    _vm.memberFilterListWithoutLoginUser.length > 0
                      ? _c(
                          "ul",
                          _vm._l(
                            _vm.memberFilterListWithoutLoginUser,
                            function (member) {
                              return _c(
                                "li",
                                {
                                  key: "member_" + member.userId,
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleMemberSelect(member)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "name-box" }, [
                                    _c("span", {
                                      staticClass: "check-box",
                                      class:
                                        _vm.selectedMemberIds.indexOf(
                                          member.userId
                                        ) > -1
                                          ? "active"
                                          : "",
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "name",
                                        attrs: {
                                          title: `${member.realName}@${member.nickname}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(member.realName) +
                                            "@" +
                                            _vm._s(member.nickname)
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "status",
                                      class: member.isDimission
                                        ? "is-dimission"
                                        : "",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          member.isDimission ? "已离职" : "正常"
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _c("p", { staticClass: "empty" }, [_vm._v("暂无数据")]),
                  ],
            ],
            2
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "right", on: { click: _vm.handleHideTeamPopover } },
        [
          _vm._m(0),
          _vm.selectedMemberIds.length > 0
            ? _c(
                "ul",
                { staticClass: "right-list" },
                _vm._l(_vm.selectedMemberIds, function (userId) {
                  return _c(
                    "li",
                    {
                      key: "userId_" + userId,
                      on: {
                        click: function ($event) {
                          return _vm.handleMemberRemove(userId)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "name-box" }, [
                        _c("span", { staticClass: "check-box active" }),
                        _c(
                          "span",
                          {
                            staticClass: "name",
                            attrs: {
                              title:
                                _vm.selectedMemberInfo[userId] &&
                                _vm.selectedMemberInfo[userId].name,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.selectedMemberInfo[userId] &&
                                  _vm.selectedMemberInfo[userId].name
                              )
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "status",
                          class: _vm.selectedMemberInfo[userId].isDimission
                            ? "is-dimission"
                            : "",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.selectedMemberInfo[userId].isDimission
                                ? "已离职"
                                : "正常"
                            )
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _c("p", { staticClass: "empty" }, [_vm._v("暂无数据")]),
          _c("div", { staticClass: "right-bottom" }, [
            _c("span", [
              _vm._v("已选" + _vm._s(_vm.selectedMemberIds.length) + "人"),
            ]),
            _c(
              "div",
              { staticClass: "right-bottom-btns" },
              [
                _vm.isSingleSelect
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "normal" },
                        on: { click: _vm.handleCancel },
                      },
                      [_vm._v("取消")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "normal" },
                        on: { click: _vm.handleClear },
                      },
                      [_vm._v("清除")]
                    ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.handleConfirm()
                      },
                    },
                  },
                  [_vm._v("确定")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "right-top" }, [
      _c("span", { staticClass: "name" }, [_vm._v("名称")]),
      _c("span", { staticClass: "status" }, [_vm._v("状态")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }