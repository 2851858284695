<template>
    <div class="team-member-select" v-loading="loading">
        <div class="left" v-loading="leftLoading">
            <div class="left-top">
                <el-popover
                    v-loading="teamTreeLoading"
                    popper-class="team-tree-popover"
                    placement="bottom-start"
                    width="242"
                    trigger="click"
                    @click.stop="() => {}"
                    v-model="teamTreePopoverVisible"
                >
                    <el-tree 
                        :data="teamData" 
                        :props="defaultProps" 
                        @node-click="handleNodeClick" 
                        :highlight-current="false"
                        :expand-on-click-node="false"
                        node-key="teamNodeKey"
                        :default-expanded-keys="['0_0_0']"
                    >
                        <span class="custom-tree-node" slot-scope="{ node, data }" :class="data.teamId == teamId ? 'active' : ''">
                            <span :title="node.label">{{ node.label }}</span>
                            <i>({{data.membersCount}})</i>
                        </span>
                    </el-tree>
                    <el-input
                        slot="reference"
                        :readonly="true"
                        class="left-top-input team-input"
                        placeholder="选择团队"
                        suffix-icon="el-icon-arrow-down"
                        v-model="teamName"
                    ></el-input>
                </el-popover>
                <!-- keyup.enter.native -->
                <el-input 
                    class="left-top-input" 
                    placeholder="搜索成员名称" 
                    v-model="filterKeyword" 
                    @input="handleFilterByKeyword" 
                    @focus="handleHideTeamPopover"
                ></el-input>
            </div>
            <div class="left-list" @click="handleHideTeamPopover">
                <div class="left-list-title">
                    <div v-if="!isSingleSelect" class="name-box" @click="handleMemberSelectAll">
                        <span class="check-box" :class="isSelectAll ? 'active' : ''"></span>
                        <span class="name">全选</span>
                    </div>
                    <div v-else class="name-box">人选</div>
                    <el-dropdown v-if="isHistoryData" placement="bottom" @command="handleFilterList">
                        <span class="el-dropdown-link">
                            {{statusFilterText || '状态'}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown" class="status-dropdown-menu">
                            <el-dropdown-item command="0">全部</el-dropdown-item>
                            <el-dropdown-item command="1">正常</el-dropdown-item>
                            <el-dropdown-item command="2">已离职</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <span class="left-list-title-status" v-else>状态</span>
                </div>
                <template v-if="!filterLoginUser">
                    <ul v-if="computedMemberFilterList.length > 0">
                        <li v-for="member in computedMemberFilterList" :key="'member_' + member.userId" @click="handleMemberSelect(member)">
                            <div class="name-box">
                                <span class="check-box" :class="selectedMemberIds.indexOf(member.userId) > -1 ? 'active' : ''"></span>
                                <span class="name" :title="`${member.realName}@${member.nickname}`">{{member.realName}}@{{member.nickname}}</span>
                            </div>
                            <span class="status" :class="member.isDimission ? 'is-dimission' : ''">{{member.isDimission ? '已离职' : '正常'}}</span>
                        </li>
                    </ul>
                    <p v-else class="empty">暂无数据</p>
                </template>
                <template v-else>
                    <ul v-if="memberFilterListWithoutLoginUser.length > 0">
                        <li v-for="member in memberFilterListWithoutLoginUser" :key="'member_' + member.userId" @click="handleMemberSelect(member)">
                            <div class="name-box">
                                <span class="check-box" :class="selectedMemberIds.indexOf(member.userId) > -1 ? 'active' : ''"></span>
                                <span class="name" :title="`${member.realName}@${member.nickname}`">{{member.realName}}@{{member.nickname}}</span>
                            </div>
                            <span class="status" :class="member.isDimission ? 'is-dimission' : ''">{{member.isDimission ? '已离职' : '正常'}}</span>
                        </li>
                    </ul>
                    <p v-else class="empty">暂无数据</p>
                </template>
            </div>
        </div>
        <div class="right" @click="handleHideTeamPopover">
            <div class="right-top">
                <span class="name">名称</span>
                <span class="status">状态</span>
            </div>
            <ul class="right-list" v-if="selectedMemberIds.length > 0">
                <li v-for="userId in selectedMemberIds" :key="'userId_' + userId" @click="handleMemberRemove(userId)">
                    <div class="name-box">
                        <span class="check-box active"></span>
                        <span class="name" :title="selectedMemberInfo[userId] && selectedMemberInfo[userId].name">{{selectedMemberInfo[userId] && selectedMemberInfo[userId].name}}</span>
                    </div>
                    <span class="status" :class="selectedMemberInfo[userId].isDimission ? 'is-dimission' : ''">{{selectedMemberInfo[userId].isDimission ? '已离职' : '正常'}}</span>
                </li>
            </ul>
            <p v-else class="empty">暂无数据</p>
            <div class="right-bottom">
                <span>已选{{selectedMemberIds.length}}人</span>
                <div class="right-bottom-btns">
                    <el-button v-if="isSingleSelect" type="normal" @click="handleCancel">取消</el-button>
                    <el-button v-else type="normal" @click="handleClear">清除</el-button>
                    <el-button type="primary" @click="handleConfirm()">确定</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TeamService from '#/js/service/teamService.js';
import moment from 'moment';
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        isSingleSelect: {
            type: Boolean,
            default: false
        },
        singleSelectTip: String,
        filterLoginUser: {
            type: Boolean,
            default: false
        },
        isShowDimission: {
            type: Boolean,
            default: false
        },
        isAllMembers: {
            type: Boolean,
            default: false
        },
        isHistoryData: {
            type: Boolean,
            default: false
        },
        isFamousCompanyOpportunity: {
            type: Boolean,
            default: false
        },
        date: {
            type: Array,
            default: function() {
                return []
            }
        },
        selected: {
            type: Array,
            default: function() {
                return []
            }
        },
        isFinancial: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            loading: false,
            leftLoading: false,
            teamTreeLoading: false,
            allMembersCount: 0,
            teamData: [],
            memberList: [],
            memberFilterList: [],
            allMembersList: [],
            filterStatus: 0,
            statusFilterText: '',
            filterKeyword: '',
            isSelectAll: false,
            selectedMemberIds: [],
            selectedMemberInfo: {},
            defaultProps: {
                children: 'childTeams',
                label: 'teamName'
            },
            teamId: '0',
            teamName: '',
            teamTreePopoverVisible: false,
        }
    },
    mounted() {
        if(!this.isHistoryData) {
            this.getTeamData();
            this.getTeamMembers();
        }

        document.body.addEventListener('click', () =>{
            if(!this.isSingleSelect) {
                this.teamTreePopoverVisible = false;
            }
        }, false)
    },
    computed: {
        loginUserId() {
            return this.$store.state.user.userInfo.id;
        },
        memberFilterListWithoutLoginUser() {
            return this.memberFilterList.filter(item => {
                return item.userId != this.loginUserId;
            })
        },
        selectedText() {
            let selectedText = '';
            if(this.selectedMemberIds.length == 0) {
                return '';
            }
            if(this.allMembersCount && (this.selectedMemberIds.length == this.allMembersCount)) {
                return '全部成员';
            }
            Object.keys(this.selectedMemberInfo).forEach(key => {
                if(selectedText == '') {
                    selectedText = this.selectedMemberInfo[key].name;
                }else {
                    selectedText = selectedText + '，' + this.selectedMemberInfo[key].name;
                }
            })
            return selectedText;
        },
        // 数据根据下拉标签做一个过滤
        computedMemberFilterList() {
            if(this.filterStatus == 1) {
                return this.memberFilterList.filter(member => !member.isDimission);
            }else if(this.filterStatus == 2) {
                return this.memberFilterList.filter(member => member.isDimission);
            } else {
                return this.memberFilterList;
            }
        },
    },
    watch: {
        visible: function(val, oldVal) {
            this.selectedMemberIds = [];
            this.selectedMemberInfo = {};
            if(val && this.selected && this.selected.length > 0) {
                this.selected.forEach(id => {
                    this.selectedMemberIds.push(id);
                    let member = null;
                    this.memberList.some(item => {
                        if(item.userId == id) {
                            member = item;
                            return true;
                        }
                    })
                    if(member) {
                        this.selectedMemberInfo[member.userId] = {
                            name: `${member.realName}@${member.nickname}`,
                            isDimission: member.isDimission
                        }
                    }
                })
                this.checkIsAllSelected();
            }

            if(!val) {
                this.teamId = '0';
                this.teamName = '';
                this.filterKeyword = '';
                this.memberList = this.allMembersList;
                this.memberFilterList = this.allMembersList;
                this.checkIsAllSelected();
            }
        }
    },
    methods: {
        handleHideTeamPopover() {
            this.teamTreePopoverVisible = false;
        },
        getTeamData() {
            this.teamTreeLoading = true;
            TeamService.getFirmTeams({
                isShowDimission: this.isShowDimission,
                isAllMembers: this.isAllMembers,
                mustHasMembers: true //过滤无成员的团队
            }).then(res => {
                this.processTeamData(res);
                this.teamData = res;
            }).finally(() => {
                this.teamTreeLoading = false;
            })
        },
        processTeamData(data) {
            if(data && data.length > 0) {
                data.forEach((item, index) => {
                    item.teamNodeKey = `${item.teamId}_${item.level}_${index}`;
                    if(item.childTeams && item.childTeams.length > 0) {
                        this.processTeamData(item.childTeams);
                    }
                })
            }
        },

        historyFirmTeamsRequest() {
            if(this.isFinancial) {
                return TeamService.getHistoryFirmTeamsForFinancial({
                    startDate: moment(this.date[0]).format('YYYY-MM-DD'),
                    endDate: moment(this.date[1]).format('YYYY-MM-DD')
                });
            } else {
                return TeamService.getHistoryFirmTeams({
                    starDate: moment(this.date[0]).format('YYYY-MM-DD'),
                    endDate: moment(this.date[1]).format('YYYY-MM-DD')
                });
            }
        },
        
        getHistoryTeamData(isInit) {
            this.teamTreeLoading = true;
            if(isInit) {
                this.handleReset();
            }
            this.historyFirmTeamsRequest().then(res => {
                if(this.teamId !== -1 && this.teamId != '0') {
                    this.handleCurrentNodeChange(this.getTeamItem(this.teamId, res.childTeams), isInit);
                } else {
                    this.teamId = res.teamId;
                    this.handleCurrentNodeChange(res, isInit);
                }
                res = [res];
                this.processTeamData(res);
                this.teamData = res;
            }).finally(() => {
                this.teamTreeLoading = false;
            })
        },
        getTeamMembers() {
            this.leftLoading = true;
            TeamService.getTeamMembers({
                teamId: this.teamId,
                take: -1,
                isShowDimission: this.isShowDimission
            }).then(res => {
                // this.memberList = res.list || [];
                // this.memberFilterList = res.list || [];

                // 选择创建者进行搜索时，排第一为自己
                let indexTemp;
                let loginUserMember;
                let needHandle = res.list.some((item,index) => {
                    if(item.userId == this.loginUserId) {
                        indexTemp = index;
                        loginUserMember = item;
                    }
                    return item.userId == this.loginUserId;
                })
                if(needHandle) {
                    res.list.splice(indexTemp,1);
                    res.list.unshift(loginUserMember);
                }
                this.memberList = res.list || [];
                this.memberFilterList = res.list || [];

                if(this.teamId == 0) {
                    this.allMembersList = res.list;
                    this.allMembersCount = res.list.length;
                }

                if(this.filterStatus == 1) {
                    this.memberFilterList = this.memberList.filter(member => {
                        return member.isDimission == false;
                    })
                    this.statusFilterText = '正常';
                }else if(this.filterStatus == 2) {
                    this.memberFilterList = this.memberList.filter(member => {
                        return member.isDimission == true;
                    })
                    this.statusFilterText = '已离职';
                } 
                this.checkIsAllSelected();       
            }).finally(() => {
                this.leftLoading = false;
            })
        },
        getHistoryTeamMembersRequest(team) {
            if(this.isFinancial) {
                return TeamService.getHistoryTeamMembersForFinancial({
                    take: -1,
                    firmTeamIds: [team.teamId].concat(team.childTeamsIds),
                    startDate: moment(this.date[0]).format('YYYY-MM-DD'),
                    endDate: moment(this.date[1]).format('YYYY-MM-DD')
                });
            } else {
                return TeamService.getHistoryTeamMembers({
                    take: -1,
                    firmTeamIds: [team.teamId].concat(team.childTeamsIds),
                    starDate: moment(this.date[0]).format('YYYY-MM-DD'),
                    endDate: moment(this.date[1]).format('YYYY-MM-DD')
                });
            }
        },
        getHistoryTeamMembers(team, isInit) {
            this.leftLoading = true;
            this.getHistoryTeamMembersRequest(team).then(res => {
                // this.memberList = res.list;
                // this.memberFilterList = res.list;

                // 选择创建者进行搜索时，排第一为自己
                let indexTemp;
                let loginUserMember;
                let needHandle = res.list.some((item,index) => {
                    if(item.userId == this.loginUserId) {
                        indexTemp = index;
                        loginUserMember = item;
                    }
                    return item.userId == this.loginUserId;
                })
                if(needHandle) {
                    res.list.splice(indexTemp,1);
                    res.list.unshift(loginUserMember);
                }
                this.memberList = res.list || [];
                this.memberFilterList = res.list || [];
                
                if(this.teamId == 0) {
                    this.allMembersList = res.list;
                    this.allMembersCount = res.list.length;
                }

                // this.memberFilterList[this.memberFilterList.length - 1].isDimission = true;

                if(this.filterStatus == 1) {
                    this.memberFilterList = this.memberList.filter(member => {
                        return member.isDimission == false;
                    })
                    this.statusFilterText = '正常';
                }else if(this.filterStatus == 2) {
                    this.memberFilterList = this.memberList.filter(member => {
                        return member.isDimission == true;
                    })
                    this.statusFilterText = '已离职';
                }
                this.checkIsAllSelected();

                if(isInit) {
                    // this.handleMemberSelectAll();
                    this.handleConfirm(isInit);
                }
            }).finally(() => {
                this.leftLoading = false;
            })
        },
        handleCurrentNodeChange(item, isInit) {
            if(item && item.teamId) {
                this.teamId = item.teamId;
                this.getHistoryTeamMembers(item, isInit);
            } else {
                this.teamId = this.teamData.teamId;
                this.getHistoryTeamMembers(this.teamData[0], isInit);
            }
        },
        getTeamItem(teamId, team) {
            let resultTeam = team.find(item => item.teamId == teamId);
            if(resultTeam) {
                return resultTeam;
            } else {
                for(let i = 0; i < team.length; i++) {
                    if(team[i].childTeams && team[i].childTeams.length) {
                        resultTeam = this.getTeamItem(teamId, team[i].childTeams);
                        if(resultTeam) {
                            return resultTeam;
                        }
                    }
                }
            }
        },
        checkIsAllSelected() {
            let selectedCount = 0;
            this.memberFilterList.forEach(member => {
                if(this.selectedMemberIds.indexOf(member.userId) > -1) {
                    selectedCount += 1;
                }
            })
            if(selectedCount > 0 && selectedCount == this.memberFilterList.length) {
                this.isSelectAll = true;
            }else {
                this.isSelectAll = false;
            }
        },
        handleFilterByKeyword() {
            let keyword = this.filterKeyword.trim();
            console.log(keyword);
            if(keyword) {
                this.memberFilterList = this.memberList.filter(member => {
                    // return member.realName.indexOf(keyword) > -1 || member.nickname.indexOf(keyword) > -1;
                    // 支持大小写不敏感
                    return member.realName.indexOf(keyword.toUpperCase()) > -1 || member.realName.indexOf(keyword.toLowerCase()) > -1 || member.nickname.indexOf(keyword.toUpperCase()) > -1|| member.nickname.indexOf(keyword.toLowerCase()) > -1;
                });
            }else {
                this.memberFilterList = this.memberList;
            }

            if(this.filterStatus == 1) {
                this.memberFilterList = this.memberFilterList.filter(member => {
                    return member.isDimission == false;
                })
            }else if(this.filterStatus == 2) {
                this.memberFilterList = this.memberFilterList.filter(member => {
                    return member.isDimission == true;
                })
            }
        },
        handleFilterList(status) {
            this.filterStatus = status;
            if(status == 0) {
                this.memberFilterList = this.memberList;
                this.statusFilterText = '全部';
            }else if(status == 1) {
                this.memberFilterList = this.memberList.filter(member => {
                    return member.isDimission == false;
                })
                this.statusFilterText = '正常';
            }else {
                this.memberFilterList = this.memberList.filter(member => {
                    return member.isDimission == true;
                })
                this.statusFilterText = '已离职';
            }
            if(this.filterKeyword) {
                this.handleFilterByKeyword(this.filterKeyword);
            }
            this.checkIsAllSelected();
        },
        handleNodeClick(data) {
            console.log(data);
            this.teamId = data.teamId;
            this.teamName = data.teamName;
            this.teamTreePopoverVisible = false;
            if(this.isHistoryData) {
                this.getHistoryTeamMembers(data);
            }else {
                this.getTeamMembers();
            }
        },
        handleMemberSelect(member) {
            let index = this.selectedMemberIds.indexOf(member.userId);
            if(index != -1) {
                this.selectedMemberIds.splice(index, 1);
                delete this.selectedMemberInfo[member.userId];
            }else {
                if(this.isSingleSelect && this.selectedMemberIds.length >= 1) {
                    shortTips(this.singleSelectTip || '当前仅支持单选');
                    return false;
                }

                this.selectedMemberIds.push(member.userId);
                this.selectedMemberInfo[member.userId] = {
                    name: `${member.realName}@${member.nickname}`,
                    isDimission: member.isDimission
                }
            }
            this.checkIsAllSelected();
        },
        handleMemberRemove(userId) {
            let index = this.selectedMemberIds.indexOf(userId);
            if(index != -1) {
                this.selectedMemberIds.splice(index, 1);
                delete this.selectedMemberInfo[userId];
            }
        },
        handleMemberSelectAll() {
            // this.memberFilterList.forEach(member => {
            this.computedMemberFilterList.forEach(member => {
                let index = this.selectedMemberIds.indexOf(member.userId);
                if(index != -1 && this.isSelectAll) {
                    this.selectedMemberIds.splice(index, 1);
                    delete this.selectedMemberInfo[member.userId];
                }
                if(index == -1 && !this.isSelectAll) {
                    this.selectedMemberIds.push(member.userId);
                    this.selectedMemberInfo[member.userId] = {
                        name: `${member.realName}@${member.nickname}`,
                        isDimission: member.isDimission
                    }
                }
            })
            this.isSelectAll = !this.isSelectAll;
        },
        selectByIdList(ids) {
            this.selectedMemberIds = ids;
            this.selectedMemberInfo = {};
            ids.forEach(id => {
                this.memberList.some(member => {
                    if(member.userId == id) {
                        this.selectedMemberInfo[id] = {
                            name: `${member.realName}@${member.nickname}`,
                            isDimission: member.isDimission
                        }
                        return true;
                    }
                })
            });
            this.checkIsAllSelected();
        },
        handleReset() {
            this.handleClear()
            this.teamId = 0;
            this.teamName = '';
            this.allMembersCount = 0;
            this.teamData = [];
            this.memberList = [];
            this.memberFilterList = [];
            this.statusFilterText = '';
            this.filterKeyword = '';
        },
        handleClear() {
            this.selectedMemberIds = [];
            this.selectedMemberInfo = {};
            this.isSelectAll = false;
        },
        handleCancel() {
            this.$emit('cancel');
        },
        handleConfirm(isInit) {
            if(isInit) {
                let ids = [];
                this.memberList.forEach(item => {
                    ids.push(item.userId);
                });
                this.$emit('item-select-cb', ids, '全部成员');
            }else {
                // 大厂机会职位选择可跟进的成员，非必选，且选择人数上限为20人
                if(this.isFamousCompanyOpportunity) {
                    if(this.selectedMemberIds.length > 20) {
                        shortTips('最多选择20位成员！');
                        return false;
                    }
                } else {
                    if(this.selectedMemberIds.length == 0) {
                        shortTips('请先选择成员！');
                        return false;
                    }
                }
    
                this.$emit('item-select-cb', this.selectedMemberIds, this.selectedText);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .team-member-select {
        display: flex;
        width: 100%;
        height: 380px;
        background-color: #fff;

        .empty {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 14px;
            color: #999;
            text-align: center;
            min-height: 200px;
        }

        .check-box {
            flex-shrink: 0;
            display: inline-block;
            width: 14px;
            height: 14px;
            margin: 2px 20px 0 17px;
            background: url('~@src/assets/images/project/unchecked.svg') no-repeat 0 0;
            background-size: 100% 100%;

            &.active {
                background-image: url('~@src/assets/images/project/checked.svg');
            }
        }

        .left {
            flex: 1;
            flex-grow: 1;
            padding: 10px;
            border-right: 1px solid #eee;

            &-top {
                .left-top-input {
                    width: 160px;
                    height: 30px;

                    /deep/ .el-input__inner {
                        height: 30px;
                        line-height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    /deep/ .el-input__icon {
                        line-height: 30px;
                    }
                }
                .team-input {
                    margin-right: 10px;
                }
            }

            &-list {
                margin-top: 20px;

                &-title {
                    display: flex;
                    align-items: center;
                    height: 32px;
                    font-size: 14px;
                    color: #333;
                    background-color: #F8F8F8;

                    &-status {
                        width: 98px;
                        padding-left: 24px;
                    }

                    .name-box {
                        display: flex;
                        align-items: center;
                        flex: 1;
                        flex-grow: 1;
                        cursor: default;
                    }

                    .el-dropdown {
                        width: 98px;
                    }
                    .el-dropdown-link {
                        padding-left: 24px;
                    }
                }

                > ul {
                    height: 240px;
                    overflow-y: scroll;

                    li {
                        display: flex;
                        align-items: center;
                        height: 40px;
                        font-size: 14px;
                        color: #333;
                        cursor: default;

                        &:hover {
                            background-color: #E7F7F3;
                        }

                        .name-box {
                            display: flex;
                            align-items: center;
                            width: 240px;

                            .name {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }

                        .status {
                            flex: 1;
                            flex-grow: 1;
                            padding-left: 24px;
                            color: #666;

                            &.is-dimission {
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }
        .right {
            position: relative;
            flex: 1;
            flex-grow: 1;
            background-color: #f5f5f5;

            &-top {
                display: flex;
                align-items: center;
                height: 32px;
                background-color: #fff;

                .name {
                    width: 275px;
                    padding-left: 61px;
                }
                .status {
                    flex: 1;
                    flex-grow: 1;
                    font-size: 14px;
                    color: #333;
                }
            }

            &-list {
                height: 300px;
                margin: 0;
                overflow-y: scroll;
                background-color: #F5F5F5;

                li {
                    display: flex;
                    align-items: center;
                    height: 40px;
                    font-size: 14px;
                    color: $primary;
                    cursor: default;

                    .name-box {
                        display: flex;
                        align-items: center;
                        width: 275px;

                        .name {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    .status {
                        flex: 1;
                        flex-grow: 1;
                        color: #666;

                        &.is-dimission {
                            color: $primary;
                        }
                    }
                }
            }

            &-bottom {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 48px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 12px;
                border-top: 1px solid #eee;
                background-color: #fff;
                
                > span {
                    font-size: 14px;
                    color: #999;
                    margin-left: 26px;
                }

                &-btns {
                    display: flex;
                }
            }
        }
    }
</style>
<style lang="scss">
    .team-member-popover {
        padding: 0px;
        transform: translateX(80px);

        .popper__arrow {
            display: none;
        }
    }

    .team-tree-popover {
        max-height: 267px;
        overflow-y: scroll;

        .el-tree-node {
            overflow: unset!important;

            > .el-tree-node__children {
                overflow: unset!important;

                .el-tree-node__content:hover {
                    background-color: #E7F7F3;
                }

                .custom-tree-node {
                    display: flex;
                    max-width: 100%;
                    min-width: 160px;

                    &.active {
                        color: $primary;
                    }
                    
                    span {
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    i {
                        padding-right: 20px;
                    }
                }
            }
        }
    }

    .status-dropdown-menu {
        width: 90px;
        .el-dropdown-menu__item {
            padding: 0;
            padding-left: 20px;
        }
    }
</style>